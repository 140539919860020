<template>
  <div class="relative page-bg-gradient min-h-full">
    <Headers :title="$t('head.route6')"></Headers>
    <div
      class="
        absolute
        top-31px
        right-0
        w-138px
        h-207px
        bg-[#ff56f599]
        !filter
        !blur-100px
        z-99
      "
    ></div>
    <div
      class="
        absolute
        top-294px
        left-0
        w-138px
        h-207px
        bg-[#2586deb3]
        !filter
        !blur-100px
        z-99
      "
    ></div>
    <div class="pt-92px">
      <img
        class="w-96px h-96px mx-auto"
        src="~@/assets/new/USDT.png"
        alt=""
        srcset=""
      />
      <div class="-mt-15px text-center text-21px font-medium">
        {{ bilv.title }}
      </div>
      <div class="mt-8px text-center">
        <div
          v-if="bilv.suanli"
          class="
            inline-block
            border border-[#ffffff33] border-solid
            rounded-200px
            h-20px
            px-10px
            text-12px
            leading-20px
          "
        >
          {{ bilv.suanli }}
        </div>
      </div>
      <div class="mt-17px px-24px">
        <div
          class="
            bg-[#ffffff1a]
            rounded-24px
            backdrop-filter backdrop-blur-20px
            p-20px
          "
        >
          <div
            class="flex justify-between text-15px py-5px"
            v-if="
              bilv.tokenaddress.toLowerCase() ==
              bilv.tokenaddress1.toLowerCase()
            "
          >
            <div class="text-[#ffffffb3]">Deposit</div>
            <div class="text-text1">{{ deposit }}</div>
          </div>

          <template v-else>
            <div class="flex justify-between text-15px py-5px">
              <div class="text-[#ffffffb3]">Deposit {{ bilv.tokenname }}</div>
              <div class="text-text1">{{ deposit1.amountA }}</div>
            </div>
            <div class="flex justify-between text-15px py-5px">
              <div class="text-[#ffffffb3]">Deposit UNC</div>
              <div class="text-text1">{{ deposit1.amountB }}</div>
            </div>
          </template>

          <div class="flex justify-between text-15px py-5px">
            <div class="text-[#ffffffb3]">Hash Power</div>
            <div class="text-text1">{{ Hash }}</div>
          </div>
          <div class="flex justify-between text-15px py-5px" v-if="!isMTC">
            <div class="text-[#ffffffb3]">Earn</div>
            <div class="text-text1 font-extrabold italic">
              {{ poolearn }} UNC
            </div>
          </div>
          <!-- <div class="flex justify-between text-15px py-5px">
            <div class="text-[#ffffffb3]">APR(Max)</div>
            <div class="text-text1">{{ apr }} %</div>
          </div> -->
        </div>
      </div>

      <div class="mt-17px px-24px">
        <div
          class="
            bg-[#ffffff1a]
            rounded-24px
            backdrop-filter backdrop-blur-20px
            p-20px
          "
        >
          <div class="text-18px text-white h-30px">
            {{ $t("head.pledged") }}
          </div>

          <div
            class="flex justify-between text-15px py-5px"
            v-if="
              bilv.tokenaddress.toLowerCase() ==
              bilv.tokenaddress1.toLowerCase()
            "
          >
            <div class="text-[#ffffffb3]">{{ bilv.tokenname }}</div>
            <div class="text-text1">{{ deposit }}</div>
          </div>

          <template v-else>
            <div class="flex justify-between text-15px py-5px">
              <div class="text-[#ffffffb3]">{{ bilv.tokenname }}</div>
              <div class="text-text1">{{ deposit1.amountA }}</div>
            </div>
            <div
              class="flex justify-between text-15px py-5px"
              v-if="
                bilv.tokenaddress.toLowerCase() !=
                bilv.tokenaddress1.toLowerCase()
              "
            >
              <div class="text-[#ffffffb3]">{{ bilv.tokenname1 }}</div>
              <div class="text-text1">{{ deposit1.amountB }}</div>
            </div>
          </template>
        </div>
      </div>

      <div class="px-24px mt-50px">
        <div
          class="
            bg-gradient-to-l
            to-[#1453bf]
            from-[#24d68e]
            rounded-16px
            h-44px
            leading-44px
            text-center
          "
          @click="approve"
        >
          {{ $t("head.approve") }}
        </div>
        <div
          class="
            mt-10px
            bg-gradient-to-l
            to-[#2586de]
            from-[#e33bd8]
            rounded-16px
            h-44px
            leading-44px
            text-center
          "
          @click="clearning"
        >
          {{ $t("head.allRedemption") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from "@/components/headers";
import mixin from "@/mixins/index";
import { BigNumber } from "bignumber.js";
export default {
  mixins: [mixin],
  components: {
    Headers,
  },
  computed: {
    isMTC() {
      return this.bilv.tokenname == "MTC";
    },
  },

  data() {
    return {
      bilv: {
        pid: "",
        amount: "",
        amount1: "",
        rid: "0",
        token: "",
        tokenname: "",
        tokenname1: "",
        tokenaddress: "",
        tokenaddress1: "",
        suanli: "",
        title: "",
        img1: "",
        img2: "",
        decimals0: "",
        decimals1: "",
      },
      apr: "",
      poolearn: "",
      deposit: "0",
      deposit1: {},
      Hash: "",
      loading: false,
    };
  },
  methods: {
    approve() {
      const price = (parseFloat(this.Hash) * 1e18 * 1.5).toFixed(0);
      this.IPHPool_contract.methods
        .approve(
          this.pool.MTC4Pool2,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast("APPROVE SUCCESS");
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    clearning() {
      this.loading = true;

      if (this.isMTC) {
        this.MTC4Pool2_contract.methods
          .withdraw()
          .send({
            from: this.global.account,
          })
          .then(() => {
            this.loading = false;
            this.$toast("SUCCESS");
            this.$router.push("/");
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err);
          });
      } else {
        this.IPHPool_contract.methods
          .withdraw(this.bilv.pid)
          .send({
            from: this.global.account,
          })
          .then(() => {
            this.loading = false;
            this.$toast("SUCCESS");
            this.$router.push("/");
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err);
          });
      }
    },
    getdeposit() {
      if (this.isMTC) {
        this.MTC4Pool2_contract.methods
          .getDeposit(this.global.account)
          .call()
          .then((res) => {
            this.Hash = (res.amountR / 1e18).toFixed(6);
            this.deposit =
              res.amountA / 10 ** this.bilv.decimals0 +
              res.amountB / 10 ** this.bilv.decimals1;
            this.deposit1.amountA = (
              res.amountA /
              10 ** this.bilv.decimals0
            ).toFixed(6);
            this.deposit1.amountB = (
              res.amountB /
              10 ** this.bilv.decimals1
            ).toFixed(6);
            if (res.amountA == 0 && res.amountB == 0) {
              this.deposit = 0;
              this.deposit1.amountA = 0;
              this.deposit1.amountB = 0;
            }

            if (res.amountR == 0) {
              this.Hash = 0;
            }
          });
      } else {
        this.IPHPool_contract.methods
          .getDeposit(this.global.account, this.bilv.pid)
          .call()
          .then((res) => {
            this.Hash = (res.amountR / 1e18).toFixed(6);
            this.deposit =
              res.amountA / 10 ** this.bilv.decimals0 +
              res.amountB / 10 ** this.bilv.decimals1;
            this.deposit1.amountA = (
              res.amountA /
              10 ** this.bilv.decimals0
            ).toFixed(6);
            this.deposit1.amountB = (
              res.amountB /
              10 ** this.bilv.decimals1
            ).toFixed(6);
            if (res.amountA == 0 && res.amountB == 0) {
              this.deposit = 0;
              this.deposit1.amountA = 0;
              this.deposit1.amountB = 0;
            }

            if (res.amountR == 0) {
              this.Hash = 0;
            }
          });
      }

      this.IPHPool_contract.methods
        .APR(this.bilv.pid)
        .call()
        .then((res) => {
          this.apr = ((res.yopt / res.cic) * 100).toFixed(6);
          if (this.apr == "Infinity" || this.apr == "NaN") {
            this.apr = 0;
          }
        });
      this.IPHPool_contract.methods
        .poolEarned(this.global.account, this.bilv.pid)
        .call()
        .then((res) => {
          this.poolearn = (res / 1e18).toFixed(6);
        });
    },
  },
  watch: {
    "global.account"() {
      this.bilv.pid = this.$route.query.pid;
      this.bilv.tokenname = this.$route.query.tokenname;
      this.bilv.tokenname1 = this.$route.query.tokenname1;
      this.bilv.suanli = this.$route.query.suanli;
      this.bilv.title = this.$route.query.title;
      this.bilv.tokenaddress = this.$route.query.tokenaddress;
      this.bilv.tokenaddress1 = this.$route.query.tokenaddress1;
      this.bilv.img1 = this.$route.query.img1;
      this.bilv.img2 = this.$route.query.img2;
      this.bilv.decimals0 = this.$route.query.decimals0;
      this.bilv.decimals1 = this.$route.query.decimals1;
      this.IPHPool_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphpool_abi2,
        this.pool.iphpool2
      );
      this.MTC4Pool2_contract = new this.global.web3.eth.Contract(
        this.poolabi.MTC4Pool2_abi,
        this.pool.MTC4Pool2
      );
      this.getdeposit();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-shadow {
  box-shadow: 0px 0px 20px #ff990066 inset;
}
</style>
