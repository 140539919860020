var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative page-bg-gradient min-h-full"},[_c('Headers',{attrs:{"title":_vm.$t('head.route6')}}),_c('div',{staticClass:"absolute top-31px right-0 w-138px h-207px bg-[#ff56f599] !filter !blur-100px z-99"}),_c('div',{staticClass:"absolute top-294px left-0 w-138px h-207px bg-[#2586deb3] !filter !blur-100px z-99"}),_c('div',{staticClass:"pt-92px"},[_c('img',{staticClass:"w-96px h-96px mx-auto",attrs:{"src":require("@/assets/new/USDT.png"),"alt":"","srcset":""}}),_c('div',{staticClass:"-mt-15px text-center text-21px font-medium"},[_vm._v(" "+_vm._s(_vm.bilv.title)+" ")]),_c('div',{staticClass:"mt-8px text-center"},[(_vm.bilv.suanli)?_c('div',{staticClass:"inline-block border border-[#ffffff33] border-solid rounded-200px h-20px px-10px text-12px leading-20px"},[_vm._v(" "+_vm._s(_vm.bilv.suanli)+" ")]):_vm._e()]),_c('div',{staticClass:"mt-17px px-24px"},[_c('div',{staticClass:"bg-[#ffffff1a] rounded-24px backdrop-filter backdrop-blur-20px p-20px"},[(
            _vm.bilv.tokenaddress.toLowerCase() ==
            _vm.bilv.tokenaddress1.toLowerCase()
          )?_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Deposit")]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit))])]):[_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Deposit "+_vm._s(_vm.bilv.tokenname))]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit1.amountA))])]),_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Deposit UNC")]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit1.amountB))])])],_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Hash Power")]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.Hash))])]),(!_vm.isMTC)?_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v("Earn")]),_c('div',{staticClass:"text-text1 font-extrabold italic"},[_vm._v(" "+_vm._s(_vm.poolearn)+" UNC ")])]):_vm._e()],2)]),_c('div',{staticClass:"mt-17px px-24px"},[_c('div',{staticClass:"bg-[#ffffff1a] rounded-24px backdrop-filter backdrop-blur-20px p-20px"},[_c('div',{staticClass:"text-18px text-white h-30px"},[_vm._v(" "+_vm._s(_vm.$t("head.pledged"))+" ")]),(
            _vm.bilv.tokenaddress.toLowerCase() ==
            _vm.bilv.tokenaddress1.toLowerCase()
          )?_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v(_vm._s(_vm.bilv.tokenname))]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit))])]):[_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v(_vm._s(_vm.bilv.tokenname))]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit1.amountA))])]),(
              _vm.bilv.tokenaddress.toLowerCase() !=
              _vm.bilv.tokenaddress1.toLowerCase()
            )?_c('div',{staticClass:"flex justify-between text-15px py-5px"},[_c('div',{staticClass:"text-[#ffffffb3]"},[_vm._v(_vm._s(_vm.bilv.tokenname1))]),_c('div',{staticClass:"text-text1"},[_vm._v(_vm._s(_vm.deposit1.amountB))])]):_vm._e()]],2)]),_c('div',{staticClass:"px-24px mt-50px"},[_c('div',{staticClass:"bg-gradient-to-l to-[#1453bf] from-[#24d68e] rounded-16px h-44px leading-44px text-center",on:{"click":_vm.approve}},[_vm._v(" "+_vm._s(_vm.$t("head.approve"))+" ")]),_c('div',{staticClass:"mt-10px bg-gradient-to-l to-[#2586de] from-[#e33bd8] rounded-16px h-44px leading-44px text-center",on:{"click":_vm.clearning}},[_vm._v(" "+_vm._s(_vm.$t("head.allRedemption"))+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }